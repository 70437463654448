/* login */

$login-bg-color: $jetuc__dark !default;
$login-box-color: $jetuc__dark-light !default;
$login-box-label-color: #ccc !default;
$login-text-color: #fff !default;

* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: normal; /* Safari, Android, iOS */
    src: url('#{$jetuc__font-folder-path}/RobotoCondensed-Regular-webfont.woff') format('woff'), url('#{$jetuc__font-folder-path}RobotoCondensed-Regular-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: bold; /* Safari, Android, iOS */
    src: url('#{$jetuc__font-folder-path}/RobotoCondensed-Bold-webfont.woff') format('woff'), url('#{$jetuc__font-folder-path}RobotoCondensed-Bold-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'ST-Icon-Font';
    font-style: normal;
    font-weight: normal;
    src: url('#{$jetuc__font-folder-path}/st-font.woff') format('woff'),
    url('#{$jetuc__font-folder-path}/st-font.ttf');
}

body {
    background: $login-bg-color;
    font-family: $jetuc__font-table-body;
}

input:focus {
    outline-color: transparent;
    outline-style: none;
}

.wrapper {
    @include position-absolute;
    color: $login-text-color;
    font-size: 12px;
    text-align: left;
    text-transform: inherit;
    @media (max-width: 900px) {
        margin: 0 20px;
    }
}

//// MAIN AREA ////////////////////
.sport-select_login {

    .login-aborted {
        padding: 1em;
        .sad-face {
            font-size: 5em;
        }

        header.aborted-header {
            margin: 0;
        }

        ul {
            margin-top: 4em;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
        }

        li {
            list-style: none;
            flex: 0 0 50%;
            padding-bottom: 1em;
            span {
                padding-right: 0.4em;
            }
        }
    }
}

.sport-select_login {
    display: block;
    width: 900px;
    margin: 0 auto;

    @media (max-width: 900px) {
        width: 100%;
        .content-col-left {
            width: 100%;
            margin-top: 80px;
        }
        .content-col-right {
            width: 100%;
            header {
                position: absolute;
                top: 0;
                margin: 10px 0;
                .logo {
                    width: 50px;
                }
                .event-title {
                    width: auto;
                    margin-left: 10px;
                    h1 {
                        font-size: 20px;
                        line-height: 1;
                    }
                }
            }
        }
        .login_error {
            width: calc(100% - 40px);
        }
    }

    // Header
    header {
        margin: 30px 0;

        .logo {
            display: inline-block;
            width: 100px;
            text-align: left;
            vertical-align: top;
            img {
                width: 100%;
                height: auto;
                &[src=""] {
                    display: none;
                }
            }
        }
        .event-title {
            display: inline-block;
            width: calc(100% - 150px);
            margin-left: 30px;
            text-align: left;
            vertical-align: top;
        }
        .date-range {
            font-size: 22px;
            font-weight: normal;
        }
    }
    h1, h2, h3 {
        margin: 0;
        font-family: $jetuc__font-headline;
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        text-transform: uppercase;
    }
}

// Status Box
.login_connectivity {
    padding: 10px;
    background: $login-box-color;
    text-align: left;
    p {
        margin: 0 0 10px 0;
        font-size: 17px;
        font-weight: bold;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        position: relative;
        min-height: 40px;
        list-style: none;
        .icon {
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            margin: 10px 13px 5px 8px;
            transition: background 1s, color 1.5s;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
            font-size: 0;
            text-align: center;
            cursor: pointer;
            vertical-align: top;
            z-index: 1;
            &.icon--success {
                background: #66c102;
                color: #fff;
                &:before {
                    content: '\f00c';
                }
                &:after {
                    transform: scale(0.8);
                    opacity: 1;
                }
            }
            &.icon--failed {
                background: #b22c21;
                color: #fff;
                &:before {
                    content: '\f00e';
                }
                &:after {
                    transform: scale(0.8);
                    opacity: 1;
                }
            }
            &:before {
                font-family: ST-Icon-Font;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
            }
            &:after {
                position: absolute;
                top: -7px;
                left: -7px;
                width: 100%;
                height: 100%;
                padding: 7px;
                transform: scale(.6);
                transition: transform 0.2s, opacity 0.2s;
                border-radius: 50%;
                box-shadow: 0 0 0 3px #ccc;
                content: '';
                opacity: 0;
            }
        }
        label {
            display: inline-block;
            line-height: 40px;
            vertical-align: top;
        }

    }
}

// Error Message:
.login_error {
    position: relative;
    width: 200px;
    margin: 0 0 -12px 20px;
    padding: 5px;
    background: #b22c21;
    color: #fff;
    font-weight: bold;
    text-align: left;
    .error-list {
        padding: 0;
    }
    li {
        padding: 0 0 15px 5px;
        font-weight: normal;
        line-height: 1.1;
        list-style: none;
    }
    &:before {
        position: absolute;
        bottom: -8px;
        left: 10px;
        border-top: 10px solid #b22c21;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        content: "";
    }
    &:empty {
        display: none;
    }
}

// Login Form
.login-form {
    text-align: center;
    header {
        margin: 0;
    }
    label {
        display: block;
        color: $login-box-label-color;
        font-size: 14px;
        text-align: left;
        text-transform: uppercase;
    }
    form {
        margin: 20px;
        padding-top: 8px;
        border: 1px solid #eee;
        &.disabled {
            opacity: 0.5;
        }
        label {
            padding: 0 17px;
        }
        input {
            width: 213px;
            margin-bottom: 15px;
            padding: 7px;
            border: 1px solid $jetuc__table-column-border;
            background: $jetuc__table-row-bg-odd;
            &:focus {
                background: #fff;
            }
        }
        input[type="submit"] {
            width: 225px;
            margin-top: 10px;
            padding: 7px 20px;
            border: none;
            background-color: $jetuc__active;
            color: #fff;
            font-family: $jetuc__font-headline;
            font-size: 17px;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    footer {
        margin: 0 0 10px 0;
        p {
            height: auto;
            line-height: 17px;
            opacity: 0.7;
        }
    }
}

.content-col-left {
    display: inline-block;
    width: 300px;
    margin-top: 30px;
    padding: 5px;
    background: $login-box-color;
    font-size: 14px;
    vertical-align: top;

    // LOGGED IN Screen:
    .user {
        margin: 0 10px;
        font-size: 15px;
        font-weight: bold;
    }
    .login-success_msg {
        margin: 0 10px;
    }
    p {
        height: 45px;
        margin: 0;
        line-height: 20px;
    }
    .button--logout {
        margin: 10px;
        padding: 3px 12px;
        border: none;
        background: $jetuc__active;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.content-col-right {
    display: inline-block;
    width: calc(100% - 340px);
    margin-left: 30px;
    vertical-align: top;

    ul.ul--select {
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            position: relative;
            width: 120px;
            height: 150px;
            margin: 0 10px 10px 0;
            list-style: none;
            &:hover {
                img {
                    border-color: $jetuc__active
                }
                .sport-name {
                    background: $jetuc__active;
                    color: #fff;
                }
            }
            img {
                position: absolute;
                top: 0;
                width: 100%;
                height: auto;
                border: 1px solid #fff;
                border-bottom: none;
                background: $jetuc__active;
            }
            .sport-name {
                position: absolute;
                top: 117px;
                width: 100%;
                height: 28px;
                padding: 5px;
                background: #fff;
                color: $jetuc__dark;
                font-size: 14px;
                font-weight: bold;
                text-decoration: none;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
            }

        }
    }
}

//Footer
footer {
    margin-top: 50px;
    text-align: center;
    .sponsors {
        margin-bottom: 30px;
    }
    img {
        width: auto;
        height: 50px;
        &[src=""] {
            display: none;
        }
    }
}
