//main
$jetuc__main-color: #b22c21 !default;
$jetuc__main-color-text-over: #fff !default;
$jetuc__text-color: #1e1e1e !default;
$jetuc__border: #b5b5b5 !default;
$jetuc__accent: #3a5973 !default;
$jetuc__accent-text: #fff !default;
$jetuc__bg: #f4f4f4 !default;
$jetuc__high-contrast-bg:  #fff;
$jetuc__section-bg: #f9f9f9 !default;
$jetuc__section-border: #f9f9f9 !default;
$jetuc__element-bg: #e7e7e7 !default;
$jetuc__text: #1e1e1e !default;
$jetuc__text-light: #464646 !default;
$jetuc__main-frame-bg: #dadada !default;
$jetuc__dark: #444 !default;
$jetuc__dark-text-over: #fff !default;
$jetuc__dark-light: #737373 !default;
$jetuc__light: #f4f4f4 !default;
$jetuc__highlight-bg: #fcead4 !default;
$jetuc__active: #b22c21 !default;
$jetuc__active-text: #fff !default;
$jetuc__leader: #f8dab6 !default;
$jetuc__winner: #ec6b20 !default;
$jetuc__headline: #3a5973 !default;
$jetuc__caption-bg: #e7e7e7 !default;
$jetuc__caption-text: #464646 !default;
$jetuc__button-border: #b5b5b5 !default;
$jetuc__button-text: #464646 !default;
$jetuc__button-bg: #e7e7e7 !default;
$jetuc__button-font: Arial !default;
$jetuc__button-active-border: $jetuc__active !default;
$jetuc__button-active-bg: $jetuc__active !default;
$jetuc__button-active-text: #fff !default;
$jetuc__button-hover-border: $jetuc__active !default;
$jetuc__button-hover-bg: #dddddd !default;
$jetuc__button-hover-text: $jetuc__active !default;
$jetuc__button-dropdown-bg: #f9f9f9 !default;
$jetuc__button-dropdown-active-border: $jetuc__active !default;
$jetuc__button-dropdown-active-bg: $jetuc__button-bg !default;
$jetuc__button-dropdown-active-text: $jetuc__active !default;

//Table
$jetuc__table-row-bg-odd: transparent !default;
$jetuc__table-row-bg-even: rgba(#000, 0.05) !default;
$jetuc__table-head-bg: transparent !default;
$jetuc__table-head-text: inherit !default;
$jetuc__table-column-border: #ccc !default;
$jetuc__table-column-border-horizontal: #ccc !default;
$jetuc__table-head-border: #91a2af !default;
$jetuc__table-body-text: #000 !default;
$jetuc__table-foot-bg: #ececec !default;
$jetuc__table-foot-text: #000 !default;
$jetuc__table-foot-border: #b5b5b5 !default;
$jetuc__table-row-dark-bg: rgba(167, 167, 167, 0.1) !default;
$jetuc__table-row-dark-bg-even: rgba(167, 167, 167, 0.05) !default;

//Head 2 Head or Team Colors
$jetuc__team-A: #ef5d6e !default;
$jetuc__team-A-bg: #f8e5e5 !default;
$jetuc__team-B: #2e99bc !default;
$jetuc__team-B-light: #e5eff7 !default;
$jetuc__team-B-text: darken($jetuc__team-B, 20%);
$jetuc__team-red: #ae3429 !default;
$jetuc__team-red-bg: #f8e5e5 !default;
$jetuc__team-blue: #20527f !default;
$jetuc__team-blue-bg: #e5eff7 !default;
$jetuc__team-green: #228409 !default;
$jetuc__team-green-bg: #cbf8ab !default;

// Status Colors
$jetuc__coming-up: #ffec58 !default;
$jetuc__coming-up-bg: #fbf7dc !default;
$jetuc__finished: #2d6aad !default;
$jetuc__finished-text-over: #fff !default;
$jetuc__finished-bg: #eaf0f7 !default;
$jetuc__live: #178e47 !default;
$jetuc__live-text-over: #fff !default;
$jetuc__live-bg: #e8f4ed !default;
$jetuc__next: #6f6f6f !default;
$jetuc__next-bg: #eee !default;
$jetuc__break: #b22c21 !default;
$jetuc__break-bg: #fbe4e2 !default;
$jetuc__negative: #b22c21 !default;
$jetuc__negative-bg: #fbe4e2;
$jetuc__negative-bg-contrast: #b22c21;
$jetuc__positive: #178e47 !default;
$jetuc__positive-bg: #e8f4ed !default;
$jetuc__positive-bg-contrast: #178e47;
$jetuc__declined: #bc301f !default;

$jetuc__gold: #f7d564 !default;
$jetuc__gold-bg: #fdfcf7 !default;
$jetuc__silver: #dadada !default;
$jetuc__silver-bg: #fcfcfc !default;
$jetuc__bronze: #f7d3b9 !default;
$jetuc__bronze-bg: #fefbfa !default;

$jetuc__traffic-stop: #bc301f;
$jetuc__traffic-stop-bg: #fbe4e2;
$jetuc__traffic-stop-bg-accent: #ffa07a;
$jetuc__traffic-ready: #ffec58;
$jetuc__traffic-ready-bg: #fbf7dc;
$jetuc__traffic-ready-bg-accent: #ffec58;
$jetuc__traffic-go:  #178e47;
$jetuc__traffic-go-bg: #e8f4ed;
$jetuc__traffic-go-bg-accent: #90ee90;


$jetuc__card-yellow: #ffec58 !default;
$jetuc__card-red: #bc301f !default;

//Trikot colors
$jetuc__jersey-blue: #1b1fff !default;
$jetuc__jersey-yellow: #ffec58 !default;
$jetuc__jersey-red: #e60302 !default;
$jetuc__jersey-purple: #c84aff !default;
$jetuc__jersey-cyan: #1fafaf !default;
$jetuc__jersey-silver: #c0c0c0 !default;


// Favorite Colors
$jetuc__selected-color-1: #dc5a21 !default;
$jetuc__selected-color-1-bg: #fecd85 !default;
$jetuc__selected-color-2: #9a49da !default;
$jetuc__selected-color-2-bg: #e8c6ff !default;
$jetuc__selected-color-3: #5a5959 !default;
$jetuc__selected-color-3-bg: #b8b7b7 !default;
$jetuc__selected-color-4: #2650da !default;
$jetuc__selected-color-4-bg: #aec6ff !default;
$jetuc__selected-color-5: #75913d !default;
$jetuc__selected-color-5-bg: #c3d0ab !default;
$jetuc__selected-color-6: #dac12a !default;
$jetuc__selected-color-6-bg: #fef68a !default;
$jetuc__selected-color-7: #003c6e !default;
$jetuc__selected-color-7-bg: #a6bfd4 !default;
$jetuc__selected-color-8: #f997be !default;
$jetuc__selected-color-8-bg: #feeaf2 !default;

$jetuc__icon-font-family: "ST-Icon-Font";
$jetuc__icon-track: "\e927";
$jetuc__icon-field: "\e928";
$jetuc__icon-road: "\e929";
$jetuc__icon-marker: "\f0de";
$jetuc__icon-favorite: "\f005";
$jetuc__icon-no-favorite: "\f006";
$jetuc__icon-close: "\f00f";
$jetuc__icon-stats: "\e923";
$jetuc__icon-wave-starter: "\e90f";
$jetuc__icon-photo: "\f030";
$jetuc__icon-notification: "\f0f3";
$jetuc__icon-lock: "\e92b";

