$login-bg-color: #eee;
$login-box-color: #dcdcdc;
$login-box-label-color: #333 !default;
$login-text-color: #333;

$jetuc__font-folder-path: "../../libs/jetuniversalcomponents/css/fonts";

@import "../../libs/jetuniversalcomponents/css/properties/mixinsJetUC";
@import "./jetuc_login";

.sponsors {
    img {
        background: $login-box-color;
        padding: 3px;
    }
}